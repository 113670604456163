@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,400;1,100;1,400&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Public Sans', sans-serif;
  background-color: #f5f5f9;
}
